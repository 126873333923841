import Api from "./api";
import { AxiosResponse } from "axios";

export class AccessApi extends Api {
  protected token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public getRoles(): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/access", {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getRolesUser(id: string, page: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(
      "/access/role/users/" + id + "?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public editRole(id: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/access/role/" + id, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getPermissionUser(id: string, page: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(
      "/access/permission/users/" + id + "?page=" + page,
      {
        headers: {
          Authorization: "Bearer " + this.token
        }
      }
    );
  }

  public addRole(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("access/role", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public assignRoleToUser(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("access/role/assign", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public getUserRole(userId: number): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`access/user/${userId}/role`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
