







































import { Component, Vue, Watch } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import { AccessApi } from "@/networks/Access";
import store from "../../../../store";
@Component({})
export default class Quick extends Vue {
  private users = [];
  private page = 1;
  private lastPage = 1;
  private x = "";
  mounted() {
    this.user();
    this.x = this.$route.params.name;
  }

  public async user(): Promise<void> {
    try {
      const res: AxiosResponse = await new AccessApi().getRolesUser(
        this.$route.params.id,
        this.page.toString()
      );
      this.users = res.data.data.data;
      this.lastPage = res.data.data.last_page;
      console.log(res.data.data);
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }
  @Watch("page")
  public onPageChanged() {
    this.user();
  }
}
